.file-uploader {
  .file-label {
    align-items: center;
    background-color: #fafafa;
    border-color: #eeeeee;
    border-radius: 2px;
    border-style: dashed;
    border-width: 2px;
    color: #bdbdbd;
    display: flex;
    flex-direction: column;
    flex: 1;
    outline: none;
    padding: 20px;
    transition: border .24s ease-in-out;
  }

  .thumbs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;

    .thumb {
      display: inline-flex;
      border-radius: 2px;
      border: 1px solid #eaeaea;
      margin-bottom: 8px;
      margin-right: 8px;
      width: 100px;
      height: 100px;
      padding: 4px;
      box-sizing: border-box;

      .thumb-inner {
        display: flex;
        min-width: 0;
        overflow: hidden;

        img {
          display: block;
          width: auto;
          height: 100%;
        }
      }
    }
  }
}
